import styled from 'styled-components';

export const AuthorMetaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-self: center;
`;
export const AuthorImageWrapper = styled.div`
  .author-image {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
`;
export const AuthorDesktopWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const ArticleAuthor = styled.div`
  color: #000;
  font-family: ${({ theme }) => theme.fonts.Walsheim.medium};
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 11px;
  margin-bottom: 12px;
  margin-top: 8px;
`;

export const ArticleAuthorMinimal = styled.div<{
  displayVariant: 'blogArticle' | 'relatedArticle';
}>`
  color: #000;
  font-family: ${({ theme }) => theme.fonts.Walsheim.medium};
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0;
  ${({ theme }) => theme.BreakpointMobile`
  font-size: 15px;
  line-height: 11px;
  `}
  ${({ displayVariant, theme }) =>
    displayVariant === 'relatedArticle' &&
    `
    font-size: 17px;
    line-height: 17px;

    ${theme.breakpointStrings.BreakpointMobile} {
      font-size: 15px;
    line-height: 11px;
    }
  `}
`;

export const ArticlePublicationInfo = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 11px;
`;
