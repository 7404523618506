import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import {
  ShowIfLargerThanMobile,
  ShowIfMobileOrSmaller,
} from '@components/Shared/SharedStyles';
import {
  ArticleAuthor,
  ArticleAuthorMinimal,
  ArticlePublicationInfo,
  AuthorDesktopWrapper,
  AuthorImageWrapper,
  AuthorMetaWrapper,
  AuthorWrapper,
} from './ArticleAuthorPreviewStyled';
import { IAuthorPreview } from './interfaces';

const ArticleAuthorPreview: React.FC<IAuthorPreview> = ({
  author,
  publishedAt,
  displayVariant,
}) => (
  <AuthorMetaWrapper>
    <ShowIfLargerThanMobile>
      {displayVariant === 'blogArticle' && (
        <AuthorDesktopWrapper>
          <AuthorImageWrapper>
            {author.image && (
              <SanityImage
                className="author-image"
                {...author.image}
                width={50}
              />
            )}
          </AuthorImageWrapper>

          <AuthorWrapper>
            <ArticleAuthor>
              {author.firstName}
              {author.lastName && ` ${author.lastName}`}
            </ArticleAuthor>
            <ArticlePublicationInfo>{publishedAt}</ArticlePublicationInfo>
          </AuthorWrapper>
        </AuthorDesktopWrapper>
      )}
      {displayVariant === 'relatedArticle' && (
        <ArticleAuthorMinimal displayVariant={displayVariant}>
          by {author.firstName}
          {author.lastName && ` ${author.lastName}`}
        </ArticleAuthorMinimal>
      )}
    </ShowIfLargerThanMobile>
    <ShowIfMobileOrSmaller>
      <ArticleAuthorMinimal displayVariant={displayVariant}>
        by {author.firstName}
      </ArticleAuthorMinimal>
    </ShowIfMobileOrSmaller>
  </AuthorMetaWrapper>
);

export default ArticleAuthorPreview;
