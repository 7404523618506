import React, { useEffect } from 'react';
import { graphql, PageProps, Script } from 'gatsby';
import Blog from '@components/Blog/Blog';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import { IBlogCategoryContext } from '@helpers/gatsbyNode/interfaces';
import { HeaderFragmentWithVariant } from '@components/Layout/NavigationBar/interfaces';
import { BlogQuery, ArticleDataFragment } from '../../graphql-types';
import PageWrapper from './shared/PageWrapper';
import { analyticsSnippet } from './analytics/analytics';
import trackPage from './analytics/trackPage';

const BlogOverview = ({
  data,
  pageContext,
}: PageProps<BlogQuery, IBlogCategoryContext>) => {
  const { selectedCategoryId } = pageContext;

  const allArticles = data?.articles.edges.map((edge) => edge.node) ?? [];

  let categoryFiltered: ArticleDataFragment[] = [];

  if (allArticles && selectedCategoryId) {
    categoryFiltered = allArticles.filter(
      (article) => article.category?._id === selectedCategoryId
    );
  }

  const config = data?.blogConfig;

  useSaveUtmParamsToLocalStorage();

  useEffect(() => {
    trackPage({
      pageSlug: data?.blogConfig?.slug?.localized,
    });
  }, [data?.blogConfig?.slug?.localized]);

  return (
    // TODO: Set up blog meta data & navigation bar in Sanity
    <PageWrapper
      locale={pageContext.locale}
      navigationBar={config?.navigationBar as HeaderFragmentWithVariant}
      metadata={config?.metadata}
      announcementId={data?.blogConfig?.navigationBar?.announcement?._id}
      footerBar={data?.sanityFooterBar}
    >
      <Blog
        articles={allArticles}
        categoryFiltered={categoryFiltered}
        selectedCategoryId={selectedCategoryId}
      />
    </PageWrapper>
  );
};

export const Head = () => (
  <>
    <Script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: analyticsSnippet,
      }}
    />
    <Script
      async
      src="https://tag.clearbitscripts.com/v1/pk_54aa5d556dad4b9548cc7054eba8b2ca/tags.js"
      referrerPolicy="strict-origin-when-cross-origin"
    />
  </>
);

export const query = graphql`
  query Blog(
    $id: String!
    $locale: String!
    $currentDate: Date!
    $footerBarId: String!
  ) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    blogConfig: sanityBlogConfig(_id: { eq: $id }) {
      title {
        localized(locale: $locale)
      }
      slug {
        localized(locale: $locale)
      }
      metadata {
        ...SEO
      }
      navigationBar {
        ...Header
      }
    }
    blogCategory: allSanityBlogCategory(
      filter: { catalog: { _id: { eq: $id } } }
    ) {
      nodes {
        ...BlogCategory
      }
    }
    articles: allSanityArticle(
      filter: { blog: { _id: { eq: $id } }, publishedAt: { lte: $currentDate } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          ...ArticleData
        }
      }
    }
  }
`;

// ts-prune-ignore-next
export default BlogOverview;
