import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import {
  ArticleCategory,
  ArticleContentPreview,
  ArticleImageWrapper,
  ArticleLink,
  ArticlePreviewWrapper,
  ArticleSummary,
  ArticleTitle,
  CategoryLink,
} from './ArticlePreviewStyled';
import { IArticlePreview } from './interfaces';
import ArticleAuthorPreview from './ArticleAuthorPreview';

const ArticlePreview: React.FC<IArticlePreview> = ({
  article,
  displayVariant,
}) => {
  const { titleImage, title, summary, slug, blog, category } = article;
  return (
    <ArticlePreviewWrapper displayVariant={displayVariant}>
      <ArticleLink to={`/${blog.slug.localized}/${slug.localized}`}>
        <ArticleImageWrapper>
          {titleImage && (
            <SanityImage
              {...titleImage}
              width={671}
              className="article-preview-title-image"
            />
          )}
        </ArticleImageWrapper>
      </ArticleLink>
      <ArticleContentPreview>
        <ArticleCategory>
          {category && (
            <CategoryLink to={category.slug.localized}>
              {category.categoryName?.localized?.toUpperCase()}
            </CategoryLink>
          )}
        </ArticleCategory>
        <ArticleLink to={`/${blog.slug.localized}/${slug.localized}`}>
          <ArticleTitle displayVariant={displayVariant}>
            {title.localized}
          </ArticleTitle>
          <ArticleSummary displayVariant={displayVariant}>
            {summary.localized}
          </ArticleSummary>

          <ArticleAuthorPreview
            author={article.author}
            publishedAt={article.publishedAt}
            displayVariant={displayVariant}
          />
        </ArticleLink>
      </ArticleContentPreview>
    </ArticlePreviewWrapper>
  );
};

export default ArticlePreview;
