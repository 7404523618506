import { Link } from 'gatsby';
import styled from 'styled-components';

export const ArticleImageWrapper = styled.div`
  .article-preview-title-image {
    border-radius: 12px;
  }
`;

export const ArticleLink = styled(Link)``;

export const ArticleCategory = styled.div`
  line-height: 100%;
  height: 20px;
  padding-bottom: 5px;
`;

export const CategoryLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blue.plumCake};
  font-family: ${({ theme }) => theme.fonts.Walsheim.bold};
  font-size: 14px;
  font-weight: 800;
`;

export const ArticleContentPreview = styled.div`
  color: #000;
  margin-top: 20px;
  font-size: 42px;
  font-weight: 400;
  letter-spacing: -0.62px;
  line-height: 56px;
  margin-bottom: 20px;
`;

export const ArticleTitle = styled.h2<{
  displayVariant: 'blogArticle' | 'relatedArticle';
}>`
  color: #000;
  margin-top: 5px;
  font-size: 42px;
  font-weight: 800;
  letter-spacing: -0.62px;
  line-height: 48px;
  margin-bottom: 20px;
  ${({ theme }) => theme.BreakpointTablet`
    font-size: 35px;
  `};
  ${({ theme }) => theme.BreakpointMobile`
  margin-bottom: 7px;
    font-size: 28px;
    line-height: 32px;
  `};
  ${({ displayVariant, theme }) =>
    displayVariant === 'relatedArticle' &&
    `
    font-size: 28px;
    line-height: 30px;

    ${theme.breakpointStrings.BreakpointTablet} {
      font-size: 25px;
    line-height: 27px;
    }

    ${theme.breakpointStrings.BreakpointMobile} {
      margin-bottom: 7px;
      font-size: 23px;
    line-height: 25px;
    }

  `}
`;

export const ArticleSummary = styled.div<{
  displayVariant: 'blogArticle' | 'relatedArticle';
}>`
  color: #000;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 31px;
  ${({ displayVariant, theme }) =>
    displayVariant === 'relatedArticle' &&
    `
    font-size: 17px;
    line-height: 20px;

    ${theme.breakpointStrings.BreakpointMobile} {
      display: none;
    }
  `}
  ${({ theme }) => theme.BreakpointTablet`
    font-size: 19px;
  `};
  ${({ theme }) => theme.BreakpointMobile`
    font-size: 18px;
    line-height: 24px;
  `};
`;

export const ArticlePreviewWrapper = styled.div<{
  displayVariant: 'blogArticle' | 'relatedArticle';
}>`
  padding: 20px 20px 0;
  margin-bottom: 50px;
  width: 50%;
  border-radius: 25px;
  ${({ displayVariant }) =>
    displayVariant === 'relatedArticle' &&
    `
    max-width: 680px;
    width: 100%;
    margin-bottom: 20px;
    &:nth-child(even){
      margin: 0;
    }
    padding: 20px 0 0;
    @media screen and (min-width: 767px){
      &:nth-child(even)&:nth-last-child(2){
        margin: 0 40px;
      }
      &:nth-child(even){
        margin: 0 0 0 40px;
      }
    }
  `}

  ${({ displayVariant, theme }) =>
    displayVariant === 'blogArticle' &&
    `
    ${theme.breakpointStrings.BreakpointMobile} {
        width: 100%;
        margin-bottom: 20px;
        padding: 20px 26px 0;
      }
    
      @media screen and (min-width: 991px) {
        &:nth-child(even)&:not(:first-of-type) {
          padding-right: 40px;
        }
      
      &:nth-child(odd)&:not(:first-of-type) {
        padding-left: 40px;
      }
      &:first-of-type {
        
        width: 100%;
        margin-bottom: 103px;
        display: flex;
        justify-content: space-between;
        gap: 52px;
        ${ArticleLink} {
          max-width: 55%;
        }
        ${ArticleImageWrapper} {
          display: flex;
          min-height: 336px;
        }
        ${ArticleContentPreview} {
          width: 45%;
          background-color: #fff;
          border-radius: 16px;
          max-width: 957px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }`}
`;
