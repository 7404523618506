import React from 'react';
import { ArticleDataFragment } from 'graphql-types';
import { useHeaderContext } from '@components/Layout/Header/headerContext';
import { graphql } from 'gatsby';
import { BlogArticles, BlogWrapper } from './BlogStyled';
import ArticlePreview from './ArticlePreview/ArticlePreview';

interface IBlogProps {
  articles: ArticleDataFragment[];
  categoryFiltered: ArticleDataFragment[];
  selectedCategoryId: string;
}

const Blog: React.FC<IBlogProps> = ({
  articles,
  categoryFiltered,
  selectedCategoryId,
}) => {
  const {
    state: { announcementBarVisible },
  } = useHeaderContext();

  return (
    <BlogWrapper announcementBarVisible={announcementBarVisible}>
      <BlogArticles>
        {selectedCategoryId
          ? categoryFiltered.map((article) => (
              <ArticlePreview
                key={article._id}
                article={article}
                displayVariant="blogArticle"
              />
            ))
          : articles.map((article) => (
              <ArticlePreview
                key={article._id}
                article={article}
                displayVariant="blogArticle"
              />
            ))}
      </BlogArticles>
    </BlogWrapper>
  );
};

export const query = graphql`
  fragment BlogCategory on SanityBlogCategory {
    _id
    _key
    slug {
      localized(locale: $locale)
    }
    categoryName {
      localized(locale: $locale)
    }
  }
  fragment ArticleData on SanityArticle {
    _id
    _key
    title {
      localized(locale: $locale)
    }
    titleImage {
      ...ImageWithCompleteData
    }
    summary {
      localized(locale: $locale)
    }
    author {
      firstName
      lastName
      image {
        ...ImageWithCompleteData
      }
    }
    category {
      ...BlogCategory
    }
    publishedAt
    slug {
      localized(locale: $locale)
    }
    redirects
    blog {
      slug {
        localized(locale: $locale)
      }
    }
  }
`;

export default Blog;
