import styled from 'styled-components';
import {
  ANNOUNCEMENT_SIZE,
  HEADER_SIZE,
  MOBILE_ANNOUNCEMENT_SIZE,
  MOBILE_HEADER_SIZE,
} from '@components/Layout/Header/constants';

export const BlogWrapper = styled.div<{
  announcementBarVisible?: boolean;
}>`
  width: 100%;
  margin: 0 auto;
  padding: 0 119px;
  ${({ theme }) => theme.BreakpointLargeDesktop`
    padding: 0 80px;
  `};
  ${({ theme }) => theme.BreakpointTablet`
    padding: 0 40px;
  `};
  ${({ theme }) => theme.BreakpointMobile`
    padding: 0 20px;
  `};
  &:first-of-type {
    padding-top: ${({ announcementBarVisible }) =>
      announcementBarVisible ? ANNOUNCEMENT_SIZE + HEADER_SIZE : HEADER_SIZE}px;
    ${({ theme, announcementBarVisible }) => theme.BreakpointTablet`    
      padding-top: ${
        announcementBarVisible
          ? MOBILE_ANNOUNCEMENT_SIZE + MOBILE_HEADER_SIZE
          : MOBILE_HEADER_SIZE
      }px;  
   `};
    ${({ theme }) => theme.BreakpointLargeDesktop`
    max-width: 1380px;
  `};
  }
`;

export const BlogArticles = styled.div`
  padding-top: 53px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -20px;
`;
